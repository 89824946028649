import React, { useRef, useCallback, useEffect } from "react";
import {
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Divider,
  FormHelperText,
  TextField,
  Autocomplete,
} from "@mui/material";
import { cargosOptions } from "../data/options";
import { LocationApi } from "../../../../services/external/cep";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { CuidadorAlunoEspecialForm } from "../components/cuidadorAlunoEspecialForm";
import { AuxiliarDeSecretariaForm } from "../components/auxiliarDeSecretariaForm";
import { InspetorMonitorPorteiroServenteMerendeiraForm } from "../components/inspetorMonitorPorteiroServenteMerendeiraForm";
import { OrientadorSupervisorInspetorForm } from "../components/orientadorSupervisorInspetorForm";
import { SecretarioEscolarForm } from "../components/secretarioEscolarForm";
import { AuxiliarCrecheForm } from "../components/auxiliarCrecheForm";
import { MotoristaClasseDForm } from "../components/motoristaClasseD";
import { NutricionistaForm } from "../components/nutricionistaForm";
import { PsicopedagogoForm } from "../components/psicopedagogoForm";
import { DocenteIForm } from "../components/docenteIForm";
import { DocenteIIForm } from "../components/docenteIIForm";
import { BaseForm } from "../components/baseForm";
import { criarFormularioServidores } from "../../../../services/formulariosService";

const ProcessoSeletivoServidoresPage = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      cargoPretendido: "",
      nomeCompleto: "",
      dataNascimento: "",
      cpf: "",
      rg: "",
      tituloEleitor: "",
      pisPasep: "",
      endereco: "",
      bairro: "",
      cidade: "",
      estado: "",
      cep: "",
      telefone: "",
      contaSantander: "",
      pessoaDeficiencia: "",
      declaracao1: false,
      declaracao2: false,
      declaracao3: false,
      declaracao4: false,
    },
  });

  const onSubmit = async (data) => {
    const response = await criarFormularioServidores(data);
    if (response.statusCode === 201) {
      toast.success('Formulário enviado com sucesso!');
      // navigate(urlsBilingue.formularioVisualizacao + response.data.id);
    } else {
      toast.error('Erro ao enviar formulário.');
    }
  };

  const { current: locationApi } = useRef(new LocationApi());

  const handleAutoCompleteForm = useCallback(
    async (cep) => {
      if (cep.length < 8) return;

      try {
        const response = await locationApi.searchByCep(cep);
        if (response.status === 400) {
          toast.warn(response.data.message);
          return;
        }

        const { cidade, uf, bairro, logradouro } = response.data.data;
        setValue("endereco", logradouro);
        setValue("bairro", bairro);
        setValue("cidade", cidade);
        setValue("estado", uf);
      } catch (error) {
        toast.error(
          "Não foi possível carregar o endereço, CEP não encontrado na base de dados."
        );
      }
    },
    [locationApi, setValue]
  );

  const renderCargoComponent = () => {
    const cargoPretendido = watch("cargoPretendido");
    switch (cargoPretendido) {
      case 1:
        return <AuxiliarCrecheForm control={control} errors={errors} />;
      case 2:
        return <AuxiliarDeSecretariaForm control={control} errors={errors} />;
      case 3:
        return <CuidadorAlunoEspecialForm control={control} errors={errors} />;
      case 4:
        return <DocenteIForm control={control} errors={errors} />;
      case 5:
        return <DocenteIIForm control={control} errors={errors} />;
      case 10:
        return <MotoristaClasseDForm control={control} errors={errors} />;
      case 11:
        return <NutricionistaForm control={control} errors={errors} />;
      case 14:
        return <PsicopedagogoForm control={control} errors={errors} />;
      case 6:
      case 8:
      case 9:
      case 13:
      case 16:
        return (
          <InspetorMonitorPorteiroServenteMerendeiraForm
            control={control}
            errors={errors}
          />
        );
      case 7:
      case 12:
      case 17:
        return (
          <OrientadorSupervisorInspetorForm control={control} errors={errors} />
        );
      case 15:
        return <SecretarioEscolarForm control={control} errors={errors} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const cargoPretendido = watch("cargoPretendido");

    setValue("experiencePrefeitura", "");
    setValue("experienceOutros", "");
    setValue("cursoEducacao", "");
    setValue("posGraduacao", "");
    setValue("mestrado", "");
    setValue("doutorado", "");
    setValue("cursoSuperior", "");
    setValue("cursoInformatica", "");
    setValue("cursoLegislacaoEducacional", "");
    setValue("posGraduacaoEducacaoEspecial", "");
    setValue("graduacaoPedagogia", "");
    setValue("cursoEnfermagem", "");
    setValue("cursoCargoPretendido", "");
    setValue("cursoEducacaoEspecial", "");
    setValue("cursoFormacaoProfessores", "");
    setValue("capacitacaoFormacaoContinuada", "");
    setValue("cursoFormacaoContinuada", "");
    setValue("cursoCondutoresVeiculos", "");
    setValue("capacitacaoPrimeirosSocorros", "");
    setValue("cursoAlimentacao", "");
    setValue("posGraduacaoAlimentacao", "");
  }, [watch("cargoPretendido"), setValue]);

  useEffect(() => {
    if (control._defaultValues.cep) {
      const formattedCep = control._defaultValues.cep.replace(/\D/g, "");
      handleAutoCompleteForm(formattedCep);
    }
  }, [control._defaultValues.cep, handleAutoCompleteForm]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
            <img
              src="https://app.semedspa.rj.gov.br/assets/images/prefeituras/semed.png"
              alt="Logo Prefeitura de São Pedro da Aldeia"
              style={{ maxWidth: "150px" }}
            />
            <Typography variant="h5">
              PREFEITURA MUNICIPAL DE SÃO PEDRO DA ALDEIA
            </Typography>
            <Typography variant="h6">SECRETARIA DE EDUCAÇÃO</Typography>
            <Typography variant="body1" gutterBottom>
              <b>PROCESSO SELETIVO SERVIDORES 2024 - SEMED</b>
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <BaseForm
                control={control}
                errors={errors}
                setValue={setValue}
                handleAutoCompleteForm={handleAutoCompleteForm}
              />

              <Grid item xs={12}>
                <Typography variant="h5" textAlign="center" marginBottom={2}>
                  Selecione o cargo pretendido
                </Typography>
                <Controller
                  name="cargoPretendido"
                  control={control}
                  rules={{
                    required: "Selecione um cargo.",
                    validate: (value) =>
                      value !== null || "O cargo deve ser maior que 0.",
                  }}
                  render={({ field }) => (
                    <>
                      <Autocomplete
                        {...field}
                        options={cargosOptions}
                        value={field.value.id}
                        disableClearable
                        inputValue={field.value.description}
                        getOptionLabel={(option) => option.description}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value?.id
                        }
                        onChange={(event, newValue) =>
                          field.onChange(newValue ? newValue.id : null)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione uma opção..."
                            variant="outlined"
                            fullWidth
                            error={!!errors.cargoPretendido}
                          />
                        )}
                      />
                      {errors.cargoPretendido && (
                        <FormHelperText error>
                          {errors.cargoPretendido.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </Grid>

              {renderCargoComponent()}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Controller
                      name="declaracao1"
                      control={control}
                      rules={{
                        required: "É obrigatório aceitar a declaração.",
                      }} // Adicionando regra de validação
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)} // Atualiza o estado corretamente
                        />
                      )}
                    />
                  }
                  label="Declaro não ser servidor estatutário da Rede Municipal de Ensino de São Pedro da Aldeia."
                />
                {errors.declaracao1 && (
                  <FormHelperText error>
                    {errors.declaracao1.message}
                  </FormHelperText>
                )}
                <Divider />

                <FormControlLabel
                  control={
                    <Controller
                      name="declaracao2"
                      control={control}
                      rules={{
                        required: "É obrigatório aceitar a declaração.",
                      }} // Adicionando regra de validação
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)} // Atualiza o estado corretamente
                        />
                      )}
                    />
                  }
                  label="Declaro não ser ocupante de cargos comissionados da Prefeitura Municipal de São Pedro da Aldeia."
                />
                {errors.declaracao2 && (
                  <FormHelperText error>
                    {errors.declaracao2.message}
                  </FormHelperText>
                )}
                <Divider />

                <FormControlLabel
                  control={
                    <Controller
                      name="declaracao3"
                      control={control}
                      rules={{
                        required: "É obrigatório aceitar a declaração.",
                      }} // Adicionando regra de validação
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)} // Atualiza o estado corretamente
                        />
                      )}
                    />
                  }
                  label="Declaro estar ciente e aceitar as normas do Edital n.º 002/2024, assim como dos comunicados e outros informativos eventualmente divulgados, estando ciente de que todas as exigências deverão ser cumpridas, assumindo a responsabilidade pela veracidade das informações prestadas."
                />
                {errors.declaracao3 && (
                  <FormHelperText error>
                    {errors.declaracao3.message}
                  </FormHelperText>
                )}
                <Divider />

                <FormControlLabel
                  control={
                    <Controller
                      name="declaracao4"
                      control={control}
                      rules={{
                        required: "É obrigatório aceitar a declaração.",
                      }} // Adicionando regra de validação
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)} // Atualiza o estado corretamente
                        />
                      )}
                    />
                  }
                  label={
                    <>
                      Art. 48. A ausência de apresentação de documento que
                      comprove a habilitação para o cargo a que concorre, assim
                      como a não apresentação dos documentos pessoais,
                      acarretará a desclassificação do candidato.
                      <br />
                      Art. 49. O candidato que não comprovar documentalmente, no
                      ato da convocação, as informações prestadas na inscrição
                      para fins de titulação, nos casos em que se aplicar, e/ou
                      experiência em serviço, será desclassificado do certame.
                    </>
                  }
                />
                {errors.declaracao4 && (
                  <FormHelperText error>
                    {errors.declaracao4.message}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ProcessoSeletivoServidoresPage;
